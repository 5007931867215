// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-impacto-femoroacetabular-js": () => import("./../../src/pages/blog/impacto-femoroacetabular.js" /* webpackChunkName: "component---src-pages-blog-impacto-femoroacetabular-js" */),
  "component---src-pages-blog-index-js": () => import("./../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contato-js": () => import("./../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-equipe-js": () => import("./../../src/pages/equipe.js" /* webpackChunkName: "component---src-pages-equipe-js" */),
  "component---src-pages-espaco-js": () => import("./../../src/pages/espaco.js" /* webpackChunkName: "component---src-pages-espaco-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nossaconfessionalidade-js": () => import("./../../src/pages/nossaconfessionalidade.js" /* webpackChunkName: "component---src-pages-nossaconfessionalidade-js" */),
  "component---src-pages-servicos-espacorelaxar-js": () => import("./../../src/pages/servicos/espacorelaxar.js" /* webpackChunkName: "component---src-pages-servicos-espacorelaxar-js" */),
  "component---src-pages-servicos-fisioterapia-js": () => import("./../../src/pages/servicos/fisioterapia.js" /* webpackChunkName: "component---src-pages-servicos-fisioterapia-js" */),
  "component---src-pages-servicos-index-js": () => import("./../../src/pages/servicos/index.js" /* webpackChunkName: "component---src-pages-servicos-index-js" */),
  "component---src-pages-servicos-osteopatia-js": () => import("./../../src/pages/servicos/osteopatia.js" /* webpackChunkName: "component---src-pages-servicos-osteopatia-js" */),
  "component---src-pages-servicos-personal-js": () => import("./../../src/pages/servicos/personal.js" /* webpackChunkName: "component---src-pages-servicos-personal-js" */),
  "component---src-pages-servicos-pilates-js": () => import("./../../src/pages/servicos/pilates.js" /* webpackChunkName: "component---src-pages-servicos-pilates-js" */)
}

